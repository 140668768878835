import { ValidDeepLink } from "@/components/common/deep-link/deep-link-encoding";
import { ModeNames } from "@/modes/mode";
import { clearStore } from "@faro-lotv/app-component-toolbox";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * A set of parameters that can be passed to a mode during initialization to customize its behavior
 *
 * For example the initial camera parameters from a deep link
 */
export type ModeInitialState = Record<string, string | undefined>;

/**
 * Shared state of the main 3d view
 */
export type ModeState = {
  /** Name of the current active mode */
  name: ModeNames;

  /** The initial state to use to initialize the mode */
  initialState: ModeInitialState | undefined;

  /** True if the mode is suspending to load new data */
  isLoadingData: boolean;

  /** True if the mode is transitioning to another mode */
  isTransitioning: boolean;

  /** The deep link used to initialize the current session */
  deepLink: ValidDeepLink | undefined;
};

const initialState: ModeState = {
  name: "start",
  initialState: undefined,
  isLoadingData: false,
  isTransitioning: false,
  deepLink: undefined,
};

/**
 * Slice to access global information about the current state of the main 3d view
 */
const modeSlice = createSlice({
  name: "mode",
  initialState,
  reducers: {
    /**
     * Change the current active camera the user is interacting with
     *
     * @param state Current state
     * @param action The new mode to transition to, with an optional initial state
     *
     * NOTE: The payload can be a simple ModeName instead of an object to allow the action to remain
     * compatible with the code used before the introduction of the initialState
     */
    changeMode(
      state,
      action: PayloadAction<
        ModeNames | { mode: ModeNames; initialState?: ModeInitialState }
      >,
    ) {
      if (typeof action.payload === "string") {
        state.name = action.payload;
        state.initialState = undefined;
      } else {
        state.name = action.payload.mode;
        state.initialState = action.payload.initialState;
      }
    },
    /**
     * Change the current state of the flag isLoadingData
     *
     * @param state Current state
     * @param action True if the mode is loading data
     */
    setModeIsLoadingData(state, action: PayloadAction<boolean>) {
      state.isLoadingData = action.payload;
    },
    /**
     * Change the current state of the flag isTransitioning
     *
     * @param state Current state
     * @param action True if the mode is loading data
     */
    setModeIsTransitioning(state, action: PayloadAction<boolean>) {
      state.isTransitioning = action.payload;
    },
    /**
     * Store the parsed valid deep link in the store
     *
     * @param state Current state
     * @param action The parsed valid deep link
     */
    setDeepLink(state, action: PayloadAction<ValidDeepLink>) {
      state.deepLink = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const modeReducer = modeSlice.reducer;

export const {
  changeMode,
  setModeIsLoadingData,
  setModeIsTransitioning,
  setDeepLink,
} = modeSlice.actions;
