import { Delayed } from "@/components/common/delayed";
import { ThreeContextTracker } from "@/components/common/three-context/three-context";
import { ModeTransitionManager } from "@/components/r3f/utils/mode-transition-manager";
import { ErrorHandlersContext } from "@/errors/components/error-handling-context";
import { Mode, ModeNames } from "@/modes/mode";
import { useCurrentSceneIfAvailable } from "@/modes/mode-data-context";
import {
  OverlayElementsContext,
  useOverlayElementsInitialState,
} from "@/modes/overlay-elements-context";
import { selectIsMinimapFullScreen } from "@/store/minimap-slice";
import { selectModeIsLoadingData } from "@/store/mode-selectors";
import { setModeIsTransitioning } from "@/store/mode-slice";
import { selectActiveArea } from "@/store/selections-selectors";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { Canvas, CanvasOverlay } from "@faro-lotv/app-component-toolbox";
import { ToastContext } from "@faro-lotv/flat-ui";
import { Box, CircularProgress } from "@mui/material";
import { PerformanceMonitor, useContextBridge } from "@react-three/drei";
import {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ReactReduxContext } from "react-redux";
import { getMode } from "../../modes";
import { SceneEventsContext } from "../common/scene-events-context";
import { TransparencySettingsProvider } from "../common/transparency-sliders/transparency-settings-context";
import { UiOverlayContextProvider } from "../common/ui-overlay-provider";
import { PointerFeedback } from "../devtools/pointer-feedback";
import { AllModesOverlay } from "./all-modes-overlay";

type ModeCanvasProps = {
  /** Name of the mode we want to render */
  modeName: ModeNames;
};

/** @returns a wrapper to bridge context from React to R3F */
function ContextBridge({ children }: PropsWithChildren<unknown>): JSX.Element {
  const Bridge = useContextBridge(
    ReactReduxContext,
    OverlayElementsContext,
    ErrorHandlersContext,
    ToastContext,
    SceneEventsContext,
  );
  return <Bridge>{children}</Bridge>;
}

/**
 * @returns A specialized canvas to render a mode scene and it's overlay
 */
export function ModeCanvas({ modeName }: ModeCanvasProps): JSX.Element | null {
  const mode = getMode(modeName);
  const isLoading = useAppSelector(selectModeIsLoadingData);
  const isMinimapFullscreen = useAppSelector(selectIsMinimapFullScreen);

  // The AllModesOverlay will error without an area (e.g. in empty projects)
  const hasArea = useAppSelector(selectActiveArea());

  const overlayElements = useOverlayElementsInitialState();

  const currentScene = useCurrentSceneIfAvailable();

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        position: "relative",
      }}
    >
      <TransparencySettingsProvider sheet={currentScene?.sheet}>
        <UiOverlayContextProvider>
          <OverlayElementsContext.Provider value={overlayElements}>
            <ModeComponent mode={mode} />
          </OverlayElementsContext.Provider>

          {isLoading && (
            <Delayed
              delay={500}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <CircularProgress size="100px" />
            </Delayed>
          )}

          {!isMinimapFullscreen && !mode.exclusive && hasArea && (
            <AllModesOverlay />
          )}
        </UiOverlayContextProvider>
      </TransparencySettingsProvider>
    </Box>
  );
}

type ModeComponentProps = {
  /** Current mode active in the store */
  mode: Mode;
};

function ModeComponent({ mode }: ModeComponentProps): JSX.Element {
  const dispatch = useAppDispatch();
  const canvas = useRef<HTMLCanvasElement>(null);

  const [isTransitioning, setIsTransitioning] = useState(false);

  // Update state of the transitioning so that the required components can be aware of the animation is needed
  useEffect(() => {
    dispatch(setModeIsTransitioning(isTransitioning));
  }, [dispatch, isTransitioning]);

  useLayoutEffect(() => {
    setIsTransitioning(true);
  }, [mode]);

  return (
    <>
      <Canvas style={{ position: "absolute" }} ref={canvas}>
        <ThreeContextTracker />
        <PointerFeedback>
          <PerformanceMonitor>
            <ContextBridge>
              <ModeTransitionManager
                mode={mode}
                onTransitionEnd={() => setIsTransitioning(false)}
              />
            </ContextBridge>
          </PerformanceMonitor>
        </PointerFeedback>
      </Canvas>

      {!isTransitioning && mode.ModeOverlay && (
        <CanvasOverlay canvas={canvas} sx={{ padding: 2 }}>
          <mode.ModeOverlay />
        </CanvasOverlay>
      )}
    </>
  );
}
