import { useSceneEvents } from "@/components/common/scene-events-context";
import {
  selectActiveElementForAnnotation,
  selectModeFromElement,
  selectModeName,
} from "@/store/mode-selectors";
import { changeMode } from "@/store/mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import {
  collapseAllAnnotations,
  setAnnotationExpansion,
} from "@/store/ui/ui-slice";
import { assert } from "@faro-lotv/foundation";
import {
  IElementMarkup,
  isIElementGenericAnnotation,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectIElementWorldPosition,
} from "@faro-lotv/project-source";
import { useCallback } from "react";
import { Vector3 } from "three";

export type GoToAnnotationFunction = () => void;

/**
 * @param markup to go to
 * @returns a function that will update the app state/scene to go to the specified markup
 */
export function useGoToAnnotation(
  markup: IElementMarkup,
): GoToAnnotationFunction {
  const { lookAt } = useSceneEvents();
  const store = useAppStore();
  const dispatch = useAppDispatch();
  return useCallback(() => {
    const state = store.getState();
    const annotation = selectAncestor(
      markup,
      isIElementGenericAnnotation,
    )(state);
    assert(annotation, "A markup should always be part of an annotation");

    const activeElement = selectActiveElementForAnnotation(annotation)(state);
    assert(
      activeElement,
      "Failed to compute the active element to look at the current annotation",
    );

    const mode = selectModeFromElement(activeElement, annotation.id)(state);
    assert(
      mode,
      "Failed to compute the mode to look at the current annotation",
    );
    dispatch(setActiveElement(activeElement.id));
    if (selectModeName(store.getState()) === mode) {
      const position = new Vector3().fromArray(
        selectIElementWorldPosition(annotation.id)(store.getState()),
      );
      lookAt.emit(position);
      dispatch(collapseAllAnnotations());
      dispatch(setAnnotationExpansion({ id: markup.id, expanded: true }));
    } else {
      dispatch(changeMode({ mode, initialState: { lookAtId: markup.id } }));
    }
  }, [dispatch, lookAt, markup, store]);
}
